import React from 'react';

import SEO from '../components/seo';

const DomainsForSalePage = () => (
  <div>
    <SEO title="Domains for sale" />
    <h1>Domains for sale</h1>
    <p></p>
    <p>The domain you were trying to visit is for sale, <a href="mailto:paul@pauldowman.com">contact me</a> and make an offer.</p>
  </div>
);

export default DomainsForSalePage;
